<template>
  <!-- 尽调报告制作管理 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
    </base-form>
    <!-- 数据表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <icon-button
          @click="edit(scope.row)"
          content="制作"
          icon="iconfont iconbianji3"
        />

        <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row)"

        />
        <icon-button
          content="撤回"
          icon="iconfont iconchehui"
          @click="info(scope.row)"

        />
      </template>
    </base-table>

  </div>
</template>
<script>

import baseForm from '@/components/common/base-form/base-form.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { progressApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton },
  data () {
    return {
      visible: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1

      },
      disabled: false,
      tableData: [{}, {}],
      gysData: []
    }
  },
  computed: {
    api () {
      return progressApi
    },
    formConfig () {
      return fromConfig(this)
    },
    columns () {
      return columnsConfig(this)
    }
  },
  mounted () {
    // this.handleFilter()
  },
  activated () {
    // this.handleFilter()
  },
  methods: {

    // 查看详情
    info (row) {
      this.$router.push({ path: '/business/contractFileManage/info' })
      Storage.setSession('examine', row)
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      }
    },
    // 查看详情
    edit (data) {
      this.$router.push({
        path: '/business/contractFileMake/contractFileMake',
        query: { businessId: data.keyId }
      })
      Storage.setLocal('contractInfo', data)
    }

  }
}
</script>
